define('m13/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({
    version: "##version##",

    loggedIn: true,

    wuerth: false,
    swg: false,
    hilti: true,

    environment: "m13hiltidev", // "offline" = offline Version, "m13" = default würth,  "m13dev" = würth dev, "m13swg" = default swg,  "m13swgdev" = swg dev

    initialized: false,
    calculationid: "",
    debug: true,
    xml: false,
    projektdatenInvalid: false,
    geometrieInvalid: true,
    systemInvalid: false,
    lasteinwirkungInvalid: true,
    verbindungsmittelInvalid: true,
    schraubenausrichtungInvalid: false,
    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "https://www.timberconnect.de/produktinfo/wuerth/katalog/katalog_de.pdf",
    eta: "https://www.timberconnect.de/produktinfo/wuerth/eta/eta-110190_de.pdf",
    unsaved: true,

    server: "",
    pfad: "",

    i18n: inject.service(),
    current: computed.readOnly("i18n.locale"),

    bemessungsvorschrift: "EC5 (DE)",

    username: "",
    sessionid: "",

    si: "DE",
    so: "DE",
    kl: "DE",
    kat: "DE",
    lc: "EC 1 NAD (DE)",
    ln: "DE",
    tc: "EC 5 NAD (DE)",
    tn: "DE",

    languages: {
      DE: "deutsch",
      DK: "dansk",
      EN: "englisch",
      FR: "franzoesisch",
      IT: "italienisch",
      NL: "nederlands",
      PL: "polnisch",
      ES: "spanisch",
      FI: "suomi",
      SE: "schwedisch",
      PT: "portugiesich",
      CZ: "tschechisch"
    },

    countries: {
      CL: "chile",
      DK: "daenemark",
      DE: "deutschland",
      EN: "england",
      UK: "england",
      FI: "finnland",
      FR: "frankreich",
      IE: "irland",
      NL: "niederlande",
      IT: "italien",
      LU: "luxemburg",
      HR: "kroatien",
      NO: "norwegen",
      AT: "oesterreich",
      PL: "polen",
      RO: "rumaenien",
      SE: "schweden",
      CH: "schweiz",
      SK: "slowakei",
      SI: "slowenien",
      ES: "spanien",
      CZ: "tschechien",
      HU: "ungarn",
      PT: "portugal",
      BE: "belgien"
    },

    selectedLanguage: "deutsch",
    selectedCountry: "deutschland",

    wuerthlogo: true,

    init: function init() {
      var self = this;
      this._super();

      var username = self.getParameterByName("user_name");
      var sessionid = self.getParameterByName("user_sessionid");

      self.setCountryVars();

      self.set("username", username);
      self.set("sessionid", sessionid);

      var environment = externalConfig.environments[self.get("environment")];
      var url = externalConfig[environment].uriAuthentifizierung;

      $.ajax({
        type: "POST",
        //url: "https://127.0.0.1:8000/Nutzerverwaltung/authentifiziereSession/",
        url: url,
        data: {
          username: username,
          sessionid: sessionid
        }
      }).done(function (res) {
        var authenticationResult = res.split(";");
        console.log(authenticationResult);
        var auth = parseInt(authenticationResult[0]);
        var level = parseInt(authenticationResult[1]);
        console.log(authenticationResult);
        if (auth == -1) {
          console.log("login unsuccessful.");
        } else if (auth == 0) {
          self.set("loggedIn", true);
          if (level == 0) {
            self.set("bauderMaster", false);
          } else if (level >= 1) {
            self.set("bauderMaster", true);
          }
          var applicationdata = self.get("model").application.objectAt(0);
          applicationdata.set("userName", username);
          console.log("login successful!");
        }
        var applicationdata = self.get("model").application.objectAt(0);

        // applicationdata.set('userName', username);
        applicationdata.set("spracheinput", self.get("si"));
        self.send("setSpracheInput", self.get("si"));

        applicationdata.set("spracheoutput", self.get("so"));
        applicationdata.set("kennungland", self.get("kl"));
        applicationdata.set("katalog", self.get("kat"));
        applicationdata.set("loadsCode", self.get("lc"));
        applicationdata.set("loadsNA", self.get("ln"));
        applicationdata.set("timberCode", self.get("tc"));
        applicationdata.set("timberNA", self.get("tn"));

        self.set("selectedCountry", self.get("i18n").t(self.countries[self.get("kl")]));

        var server = externalConfig[environment].server;
        var pfad = externalConfig[environment].pfad;

        // $.ajax({
        //   type: "POST",
        //   url: server + "m13/DE/initialisiereGUI/" +self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
        //   data: {
        //
        //   }
        // }).done(function(data, statusText, xhr) {

        self.controllerFor("supercontroller").logaufruf("initialisiereGUI", 200);

        //   console.log('initialisiereGUI data: ');
        //   console.log(data);
        //
        // })
      });

      self.store.createRecord("bauteile", {});
      self.store.createRecord("lasteinwirkung", {});
      self.store.createRecord("anschlussgeometrie", {});
      self.store.createRecord("projektdaten", {});

      self.transitionToRoute("projektdaten");

      // var environment = externalConfig.environments.m13;

      var environment = externalConfig.environments[self.environment];

      console.log("self.environment: " + self.environment);
      console.log("environment: " + environment);

      self.set("server", externalConfig[environment].server);
      self.set("pfad", externalConfig[environment].pfad);

      self.get("i18n").addTranslations("cz", externalTranslations.cz);
      self.get("i18n").addTranslations("de", externalTranslations.de);
      self.get("i18n").addTranslations("dk", externalTranslations.dk);
      self.get("i18n").addTranslations("ee", externalTranslations.ee);
      self.get("i18n").addTranslations("en", externalTranslations.en);
      self.get("i18n").addTranslations("es", externalTranslations.es);
      self.get("i18n").addTranslations("fi", externalTranslations.fi);
      self.get("i18n").addTranslations("fr", externalTranslations.fr);
      self.get("i18n").addTranslations("gr", externalTranslations.gr);
      self.get("i18n").addTranslations("hr", externalTranslations.hr);
      self.get("i18n").addTranslations("hu", externalTranslations.hu);
      self.get("i18n").addTranslations("it", externalTranslations.it);
      self.get("i18n").addTranslations("lt", externalTranslations.lt);
      self.get("i18n").addTranslations("lv", externalTranslations.lv);
      self.get("i18n").addTranslations("nl", externalTranslations.nl);
      self.get("i18n").addTranslations("no", externalTranslations.no);
      self.get("i18n").addTranslations("pl", externalTranslations.pl);
      self.get("i18n").addTranslations("pt", externalTranslations.pt);
      self.get("i18n").addTranslations("ro", externalTranslations.ro);
      self.get("i18n").addTranslations("se", externalTranslations.se);
      self.get("i18n").addTranslations("si", externalTranslations.si);
      self.get("i18n").addTranslations("sk", externalTranslations.sk);
    },

    getParameterByName: function getParameterByName(name, url) {
      if (!url) {
        url = window.location.href;
      }

      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    setCountryVars: function setCountryVars() {
      var self = this;

      var si = self.getParameterByName("spracheinput");
      var so = self.getParameterByName("spracheoutput");
      var kl = self.getParameterByName("kennungland");
      var kat = self.getParameterByName("katalog");
      var lc = self.getParameterByName("loadsCode");
      var ln = self.getParameterByName("loadsNA");
      var tc = self.getParameterByName("timberCode");
      var tn = self.getParameterByName("timberNA");

      if (window.location.href.indexOf("timberCode") === -1) {
        self.set("si", "DE");
        self.set("so", "DE");
        self.set("kl", "DE");
        self.set("kat", "DE");
        self.set("lc", "EC1");
        self.set("ln", "DE");
        self.set("tc", "EC5");
        self.set("tn", "DE");
        self.set("bemessungsvorschrift", "EC5 (DE)");
      } else {
        self.set("si", si);
        self.set("so", so);
        self.set("kl", kl);
        self.set("kat", kat);
        self.set("lc", lc);
        self.set("ln", ln);
        self.set("tc", tc);
        self.set("tn", tn);

        if (tc.slice(10, tc.length - 1).length === 2) {
          self.set("bemessungsvorschrift", "EC5 (" + tc.slice(10, tc.length - 1) + ")");
        } else {
          if (tc.slice(10, tc.length - 1) === "D") {
            self.set("bemessungsvorschrift", "EC5 (DE)");
          } else if (tc.slice(10, tc.length - 1) === "A") {
            self.set("bemessungsvorschrift", "EC5 (AT)");
          } else if (tc.slice(10, tc.length - 1) === "I") {
            self.set("bemessungsvorschrift", "EC5 (IT)");
          } else if (tc.slice(10, tc.length - 1) === "F") {
            self.set("bemessungsvorschrift", "EC5 (FR)");
          }
        }

        //self.send('setSpracheInput', si);
      }
    },

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor("application").get("model").application.objectAt(0);
      applicationdata.set("treeLoaded", false);
      applicationdata.set("initialized", false);
      applicationdata.set("pdfErstellt", false);

      var application = self.controllerFor("application");
      application.set("verbindungsmittelInvalid", true);

      var downloadcenter = self.controllerFor("downloadcenter");
      downloadcenter.set("pdfErstellt", false);

      self.transitionToRoute("projektdaten");
    },

    actions: {
      setSpracheInput: function setSpracheInput(spracheInput) {
        var self = this;
        this.get("i18n").set("locale", spracheInput.toLowerCase());
        var applicationdata = this.get("model").application.objectAt(0);
        applicationdata.set("spracheinput", spracheInput);
        applicationdata.set("spracheoutput", spracheInput);
        self.send("setCountryCode", spracheInput);

        self.set("selectedLanguage", self.get("i18n").t(self.languages[spracheInput]));

        if (spracheInput == "DE") {
          self.set("hilfelink", "https://timberconnect.de/hilfe/wuerth/html/de/m13___trageraufdopplung_.html?ms=AAAA&st=MA%3D%3D&sct=MTIyMA%3D%3D&mw=MzAw#"); // Würth
        } else {
            self.set("hilfelink", "https://timberconnect.de/hilfe/wuerth/html/en/m13___duplication_of_beams.html?ms=AAAA&st=MA%3D%3D&sct=MTcyMQ%3D%3D&mw=MzAw#"); // Würth
          }

        this.controllerFor("geometrie").setSelectFieldsContent();
        this.controllerFor("system").setSelectFieldsContent();
        this.controllerFor("lasteinwirkung").setSelectFieldsContent();

        this.controllerFor("geometrie").set("initTrigger", !this.controllerFor("geometrie").set("initTrigger"));

        self.resetApplication();
      },

      cC: "DE",

      setCountryCode: function setCountryCode(countryCode) {
        var self = this;

        self.set("selectedCountry", self.get("i18n").t(self.countries[countryCode]));

        countryCode = countryCode === "EN" ? "UK" : countryCode;

        self.set("cC", countryCode);
        self.controllerFor("impressum").set("countryCode", countryCode);

        var applicationdata = this.get("model").application.objectAt(0);
        applicationdata.set("kennungland", countryCode);

        var firm = applicationdata.get("firm");

        var projektdaten = this.controllerFor("projektdaten");

        if (countryCode === "DE") {
          projektdaten.set("plzservice", true);
        } else {
          projektdaten.set("plzservice", false);
        }

        var downloadcenter = this.controllerFor("downloadcenter");

        var cC = countryCode;
        var eta = "120196";

        if (typeof linksDB.firm.findBy("id", firm) === "undefined") {
          firm = "SWG";
        }

        if (typeof linksDB.firm.findBy("id", firm).produktinfos.land.findBy("countryCode", cC) === "undefined") {
          cC = "EN";
        }

        if (firm === "WUERTH") {
          eta = "110190";
        }

        var prodkaturl = "";
        var etaurl = "";

        if (self.get("environment") === "offline") {
          prodkaturl = linksDB.firm.findBy("id", firm).produktinfos.land.findBy("countryCode", cC).links.findBy("id", "katalog").offline;
          etaurl = linksDB.firm.findBy("id", firm).produktinfos.land.findBy("countryCode", cC).links.findBy("id", eta).offline;
        } else {
          prodkaturl = linksDB.firm.findBy("id", firm).produktinfos.land.findBy("countryCode", cC).links.findBy("id", "katalog").file;
          etaurl = linksDB.firm.findBy("id", firm).produktinfos.land.findBy("countryCode", cC).links.findBy("id", eta).file;
        }

        this.set("eta", etaurl);
        this.set("produktkatalog", prodkaturl);
        // downloadcenter.set('eta', etaurl);
        // downloadcenter.set('produktkatalog', prodkaturl);

        self.set("wuerthlogo", cC === "DE" ? true : false);

        self.resetApplication();
      },

      setETA: function setETA(eta) {
        var applicationdata = this.get("model").application.objectAt(0);

        var etaurl = "assets/public/docs/eta_" + eta.toLowerCase() + ".pdf";

        this.set("eta", etaurl);
      },

      setBemessungsvorschrift: function setBemessungsvorschrift(bemessungsvorschrift) {
        var self = this;
        var applicationdata = this.get("model").application.objectAt(0);

        if (bemessungsvorschrift === "DE" || bemessungsvorschrift === "AT" || bemessungsvorschrift === "NL" || bemessungsvorschrift === "SE") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set("loadsNA", bemessungsvorschrift);
          applicationdata.set("timberNA", bemessungsvorschrift);
        } else {
          if (bemessungsvorschrift === "DK" || bemessungsvorschrift === "FI") {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
            applicationdata.set("loadsNA", "DE");
            applicationdata.set("timberNA", bemessungsvorschrift);
          } else {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            if (bemessungsvorschrift === "IT") {
              // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
              applicationdata.set("loadsNA", "DE");
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set("timberNA", bemessungsvorschrift);
            } else if (bemessungsvorschrift === "FR") {
              // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set("loadsNA", bemessungsvorschrift);
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set("timberNA", bemessungsvorschrift);
            }
          }
        }

        applicationdata.set("loadsCode", "EC1");
        applicationdata.set("timberCode", "EC5");

        this.set("bemessungsvorschrift", "EC5 (" + bemessungsvorschrift + ")");

        self.resetApplication();
      },

      downloadcenter: function downloadcenter(spracheInput) {
        this.transitionToRoute("downloadcenter");
      },

      verbindungsmittel: function verbindungsmittel(event) {
        console.log("test");
        var lasteinwirkung = this.controllerFor("lasteinwirkung");
        var applicationdata = this.get("model").application.objectAt(0);

        if (!applicationdata.get("treeLoaded")) {
          console.log("jetzt müsste der Tree neu geladen werden.");
          lasteinwirkung.send("proceedInput");
        } else {
          console.log("jetzt müsste der Tree NICHT neu geladen werden.");
        }
      },

      ergebnis: function ergebnis(event) {
        console.log("test");
        var verbindungsmittel = this.controllerFor("verbindungsmittel");

        verbindungsmittel.send("proceedInput");
      },

      newAction: function newAction(event) {
        console.log(window.location.href);

        var self = this;

        var protocol = window.location.protocol;
        var host = window.location.host;

        var applicationdata = this.get("model").application.objectAt(0);
        var si = applicationdata.get("spracheinput");
        var so = applicationdata.get("spracheoutput");
        var kl = applicationdata.get("kennungland");
        var kat = applicationdata.get("katalog");
        var lc = applicationdata.get("loadsCode");
        var ln = applicationdata.get("loadsNA");
        var tc = applicationdata.get("timberCode");
        var tn = applicationdata.get("timberNA");
        var bv = self.get("bemessungsvorschrift");

        window.location.href = protocol + "//" + host + "/?user_name=" + self.get("username") + "&user_sessionid=" + self.get("sessionid") + "&spracheinput=" + si + "&spracheoutput=" + so + "&kennungland=" + kl + "&katalog=" + kat + "&loadsCode=" + lc + "&loadsNA=" + ln + "&timberCode=" + tc + "&timberNA=" + tn + "&bemessungsvorschrift=" + bv;
      },

      contactAction: function contactAction(event) {
        var self = this;

        self.transitionToRoute("contact");
      },

      produktkatalogAnzeigen: function produktkatalogAnzeigen(event) {
        var self = this;

        self.transitionToRoute("contact");
      },

      etaAnzeigen: function etaAnzeigen(event) {
        var self = this;

        self.transitionToRoute("contact");
      },

      setCameraActiv: function setCameraActiv(camera) {
        var lastCamera = this.get("model").x3ddefault.objectAt(0).get("bauteile").findBy("id", this.get("model").x3ddefault.objectAt(0).get("lastViewpoint"));

        this.get("model").x3ddefault.objectAt(0).set("lastViewpoint", camera);

        var activeCamera = this.get("model").x3ddefault.objectAt(0).get("bauteile").findBy("id", camera);

        lastCamera.get("cameraProperties").objectAt(0).set("setBind", false);
        activeCamera.get("cameraProperties").objectAt(0).set("setBind", true);

        // resetViewpoint() zu finden in /bower_components/viewpointmanagement/vpmanagements.js
        // setzt die viewpoints zurück
        this.zentriertObjekt();

        this.get("model").x3ddefault.objectAt(0).set("transformHelper", !this.get("model").x3ddefault.objectAt(0).get("transformHelper"));
      },

      setDrahtgittermodel: function setDrahtgittermodel() {
        this.get("model").x3ddefault.objectAt(0).set("istGitterModell", !this.get("model").x3ddefault.objectAt(0).get("istGitterModell"));
      },

      saveAction: function saveAction() {
        console.log("speichern()");

        var self = this;

        // self.transitionToRoute('downloadcenter');
        var dc = self.controllerFor("downloadcenter");
        dc.set("savingInProgress", true);

        var application = self.controllerFor("application");

        var applicationdata = application.get("model").application.objectAt(0);
        var applicationdataJSON = JSON.stringify(applicationdata);

        var projektdatendata = application.get("model").projektdaten.objectAt(0);
        var projektdatendataJSON = JSON.stringify(projektdatendata);

        // var geometriedata = application.get('model').geometrie.objectAt(0);
        // var geometriedataJSON = JSON.stringify(geometriedata);
        //
        // var systemdata = application.get('model').system.objectAt(0);
        // var systemdataJSON = JSON.stringify(systemdata);
        //
        // // var schraubenausrichtungdata = application.get('model').schraubenausrichtung.objectAt(0);
        // // var schraubenausrichtungdataJSON = JSON.stringify(schraubenausrichtungdata);
        //
        // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
        // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
        //
        // if (self.debug) {
        //   console.log("projektdatendata: " + projektdatendataJSON);
        //   console.log("applicationdataJSON: " + applicationdataJSON);
        //   console.log("geometriedataJSON: " + geometriedataJSON);
        //   console.log("systemdataJSON: " + systemdataJSON);
        //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
        // }
        //
        // var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(systemdataJSON), JSON.parse(lasteinwirkungdataJSON));
        //
        // var bestandstraeger = {
        //   "Querschnittswerte": {
        //     "b": geometriedata.get('TimberElement1_b'),
        //     "h": geometriedata.get('TimberElement1_h'),
        //     "t_Fastener": geometriedata.get('TimberElement1_h'),
        //     "QuerschnittsID": "1"
        //   },
        //   "vorgebohrt": false
        // };
        //
        // var verstaerkungstraeger = {
        //   "Querschnittswerte": {
        //     "b": geometriedata.get('TimberElement2_b'),
        //     "h": geometriedata.get('TimberElement2_h'),
        //     "t_Fastener": geometriedata.get('TimberElement2_h'),
        //     "QuerschnittsID": "1"
        //   },
        //   "vorgebohrt": false
        // };
        //
        // var schalung = {
        //   "Querschnittswerte": {
        //     "b": "0.0",
        //     "h": geometriedata.get('schalungsdicke'),
        //     "t_Fastener": geometriedata.get('schalungsdicke'),
        //     "QuerschnittsID": "1"
        //   },
        //   "vorgebohrt": false
        // };
        //
        // var externeLasten = [
        //   lasteinwirkungdata.get('staendigeEinwirkungen'), // ständige einwirkungen
        //   lasteinwirkungdata.get('verEinw1'), // ver. einw'). 1
        //   lasteinwirkungdata.get('verEinw2') // ver. einw. 2
        // ];
        //
        // var standardloads = [
        //   lasteinwirkungdata.get('eigengewicht'), // vermutlich auswahl "einfeldträger"
        //   lasteinwirkungdata.get('kled1'), // kled menu 1
        //   lasteinwirkungdata.get('kled2') // kled menu 2
        // ];
        //
        // uebergabedaten.bestandstraeger = bestandstraeger;
        // uebergabedaten.verstaerkungstraeger = verstaerkungstraeger;
        // uebergabedaten.schalung = schalung;
        // uebergabedaten.externeLasten = externeLasten;
        // uebergabedaten.lastIDs = standardloads;

        // var JSONdata = JSON.stringify(uebergabedaten);
        var JSONdata = JSON.stringify(self.controllerFor("supercontroller").uebergabedatenErzeugen());

        var initialized = applicationdata.get("initialized");

        var parasToSave = JSON.parse(JSONdata);

        var dataToSave = JSON.stringify(auxiliary.save([parasToSave]));

        //var dingsbums = JSON.stringify(dataToSave);

        var server = self.get("server");
        var pfad = self.get("pfad");

        console.log("server: " + server);
        console.log("pfad: " + pfad);

        if (!initialized) {
          $.ajax({
            type: "POST",
            url: server + "initialisiereBerechnung/",
            //url: "https://136.243.4.143/berechnungsservice/x3d/DE/erzeugeSchraubenliste/",
            data: {
              kennung: applicationdataJSON,
              // projektdaten: projektdatendataJSON,
              paras: JSONdata,
              modul: "m13",
              pfad: pfad
            }
          }).done(function (data, statusText, xhr) {
            self.controllerFor("supercontroller").logaufruf("initialisiereBerechnung", xhr.status);

            var cid = data;
            applicationdata.set("Calculation_ID", cid);
            applicationdata.set("initialized", true);
            dc.set("cid", data);

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {
              self.controllerFor("supercontroller").logaufruf("speichereBerechnung", xhr.status);

              dc.set("saveFile", data);
              dc.set("savingInProgress", false);
              dc.set("saved", true);

              var element = document.createElement("a");
              element.setAttribute("href", "data:text/plain;charset=utf-8, " + dataToSave);
              element.setAttribute("download", cid + ".hi13");
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          });
        } else {
            var cid = applicationdata.get("Calculation_ID");

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {
              self.controllerFor("supercontroller").logaufruf("speichereBerechnung", xhr.status);

              dc.set("saveFile", data);
              dc.set("savingInProgress", false);
              dc.set("saved", true);

              var element = document.createElement("a");
              element.setAttribute("href", "data:text/plain;charset=utf-8, " + dataToSave);
              element.setAttribute("download", cid + ".hi13");
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          }
        self.set("unsaved", false);
      },

      /**
       * This action is called when a file has been loaded.
       *
       * @method ACTION: fileLoaded
       */
      fileLoaded: function fileLoaded(file) {
        var self = this;

        if (self.xml) {
          var test = self.parseXml(file.data);
          console.log("test");
          console.log(xml2json(test, ""));
        } else {
          var applicationdata = self.controllerFor("application").get("model").application.objectAt(0);

          var projektdaten = self.controllerFor("projektdaten");
          var geometrie = self.controllerFor("geometrie");
          var system = self.controllerFor("system");
          var lasteinwirkung = self.controllerFor("lasteinwirkung");

          var values = JSON.parse(file.data);

          self.send("setSpracheInput", values.spracheinput);

          applicationdata.set("katalog", values.katalog);

          self.send("setBemessungsvorschrift", values.timberNA);

          applicationdata.set("kennungland", values.kennungland);
          applicationdata.set("userID", values.userID);
          applicationdata.set("userName", values.userName);

          projektdaten.setValues(JSON.parse(file.data));
          geometrie.setValues(JSON.parse(file.data)); // wegen x3d aktuell noch auskommentiert.
          system.setValues(JSON.parse(file.data)); // wegen x3d aktuell noch auskommentiert.
          lasteinwirkung.setValues(JSON.parse(file.data));
        }
      },

      toggleForm: function toggleForm() {
        $(".clearfix").toggleClass("formZu");
        $("#formToggler").toggleClass("open");

        setTimeout(function () {
          var x3dGrafik = document.getElementById("threedee");
          var x3d = document.getElementById("x3d");
          var canvas = document.getElementsByTagName("canvas");
          var x3druntime = document.getElementById("x3d").runtime;

          // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute("width", Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
          x3d.setAttribute("height", Number($(window).height()) - 300);
        }, 400);
      }
    },

    miniertX3D: function miniertX3D() {
      $(".clearfix").removeClass("formZu");
      $("#formToggler").removeClass("open");

      setTimeout(function () {
        var x3dGrafik = document.getElementById("threedee");
        var x3d = document.getElementById("x3d");
        var canvas = document.getElementsByTagName("canvas");
        var x3druntime = document.getElementById("x3d").runtime;

        // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        x3d.setAttribute("width", Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
        x3d.setAttribute("height", Number($(window).height()) - 300);
      }, 400);
    },

    parseXml: function parseXml(xml) {
      var dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, "text/xml");
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new ActiveXObject("Microsoft.XMLDOM");
          dom.async = false;
          if (!dom.loadXML(xml))
            // parse error ..

            window.alert(dom.parseError.reason + dom.parseError.srcText);
        } catch (e) {
          dom = null;
        }
      } else alert("cannot parse xml string!");
      return dom;
    },

    x3dTranslation: (function () {
      var x3d = this.get("model").x3ddefault.objectAt(0);
      var bauteile = x3d.get("bauteile");
      var y;

      y = -(Number(bauteile.findBy("id", "lager1").get("boxsizes").objectAt(0).get("y")) + Number(bauteile.findBy("id", "material1").get("boxsizes").objectAt(0).get("y")) + Number(bauteile.findBy("id", "schalung1").get("boxsizes").objectAt(0).get("y")) + Number(bauteile.findBy("id", "material2").get("boxsizes").objectAt(0).get("y"))) / 20 - 0.65;

      var translation = 0 + " " + y + " " + 0;

      return translation;
    }).property("model.x3ddefault.firstObject.transformHelper"),

    zentriertObjekt: function zentriertObjekt() {
      var x3d = this.get("model").x3ddefault.objectAt(0);
      var bauteile = x3d.get("bauteile");

      var x = 2 * (bauteile.findBy("id", "material1").get("boxsizes").objectAt(0).get("x") / 10) + 0.2;
      var y = bauteile.findBy("id", "lager1").get("boxsizes").objectAt(0).get("y") / 10 + bauteile.findBy("id", "material1").get("boxsizes").objectAt(0).get("y") / 10 + bauteile.findBy("id", "schalung1").get("boxsizes").objectAt(0).get("y") / 10 + bauteile.findBy("id", "material2").get("boxsizes").objectAt(0).get("y") / 10 + 2;
      var z = bauteile.findBy("id", "schalung1").get("boxsizes").objectAt(0).get("z") / 10;

      var arr = [x, y, z];
      arr.sort(function (a, b) {
        return b - a;
      });

      var d = 0;
      var val = Number(arr[0]);

      if (bauteile.findBy("id", "viewpointDefault").get("cameraProperties").objectAt(0).get("setBind") === true) {
        d = Math.sqrt(Math.pow(val, 2) + Math.pow(val * 0.4, 2) + Math.pow(val, 2));
      } else {
        d = arr[0];
      }

      x3d.set("aktuelleDistance", d);

      resetViewpoint(d);
    },

    schalung: (function () {
      var x3d = this.get("model").x3ddefault.objectAt(0);
      var bauteile = x3d.get("bauteile");
      var value = false;

      if (Number(bauteile.findBy("id", "schalung1").get("boxsizes").objectAt(0).get("y")) > 0 || x3d.get("schalungsdickeAktiv")) {
        value = true;
      }

      return value;
    }).property("model.x3ddefault.firstObject.transformHelper", "model.x3ddefault.firstObject.schalungsdickeAktiv"),

    systemlaengeAnzeigen: (function () {
      var value = true;
      var x3d = this.get("model").x3ddefault.objectAt(0);
      var bauteile = x3d.get("bauteile");

      if (x3d.get("abstaendeAnzeigen") === false || bauteile.findBy("id", "viewpointLinks").get("cameraProperties").objectAt(0).get("setBind") === true) {
        value = false;
      }

      return value;
    }).property("model.x3ddefault.firstObject.abstaendeAnzeigen", "model.x3ddefault.firstObject.lastViewpoint"),

    eingabenUngueltig: (function () {
      var self = this;
      var value = true;

      if (self.get("projektdatenInvalid") === false && self.get("geometrieInvalid") === false && self.get("systemInvalid") === false && self.get("lasteinwirkungInvalid") === false) {
        value = false;
      }

      return value;
    }).property("projektdatenInvalid", "geometrieInvalid", "systemInvalid", "lasteinwirkungInvalid"),

    etasExist: (function () {
      var self = this;

      var firm = self.get("model").application.objectAt(0).get("firm").toLowerCase();
      var cC = self.get("model").application.objectAt(0).get("kennungland").toLowerCase();

      var liste = Ember['default'].Set.create();

      if (cC === "uk") {
        cC = "en";
      } else if (cC === "ch") {
        cC = self.get("model").application.objectAt(0).get("spracheinput").toLowerCase();
      }

      var value = false;

      if (typeof etaConfig[firm][cC] !== "undefined" && typeof etaConfig[firm][cC]["files"] !== "undefined") {
        if (Object.values(etaConfig[firm][cC]["files"]["ETAs"]).length > 0) value = true;else {
          value = false;
          self.controllerFor("downloadcenter").set("etaFehlt", true);
        }
      } else if (typeof etaConfig[firm]["en"] !== "undefined" && typeof etaConfig[firm]["en"]["files"] !== "undefined") {
        if (Object.values(etaConfig[firm]["en"]["files"]["ETAs"]).length > 0) value = true;else {
          value = false;
          self.controllerFor("downloadcenter").set("etaFehlt", true);
        }
      } else value = false;

      return value;
    }).property("cC"),

    etaListe: (function () {
      var self = this;

      var firm = self.get("model").application.objectAt(0).get("firm").toLowerCase();
      var cC = self.get("model").application.objectAt(0).get("kennungland").toLowerCase();

      var liste = Ember['default'].Set.create();

      if (cC === "uk") {
        cC = "en";
      } else if (cC === "ch") {
        cC = self.get("model").application.objectAt(0).get("spracheinput").toLowerCase();
      }

      if (firm !== "bauder" && typeof etaConfig[firm][cC] !== "undefined" && typeof etaConfig[firm][cC]["files"] !== "undefined") {
        Object.values(etaConfig[firm][cC]["files"]["ETAs"]).forEach(function (item, i) {
          var eintrag = {
            name: item.name,
            beschreibung: item.beschreibung,
            link: "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname,
            m0601: item.m0601
          };
          if (!item.m0601) {
            liste.add(eintrag);
          }
        });
      } else if (typeof etaConfig[firm]["en"] !== "undefined" && typeof etaConfig[firm]["en"]["files"] !== "undefined") {
        Object.values(etaConfig[firm]["en"]["files"]["ETAs"]).forEach(function (item, i) {
          // console.log(item);

          var eintrag = {
            name: item.name,
            beschreibung: item.beschreibung,
            link: "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname,
            m0601: item.m0601
          };
          if (!item.m0601) {
            liste.add(eintrag);
          }
        });
      }

      if (liste.length > 0) {
        var downloadcenter = self.controllerFor("downloadcenter");
        downloadcenter.set("eta", liste.toArray()[0].link);
        downloadcenter.set("etaFehlt", false);
      }

      return liste.toArray();
    }).property("cC"),

    catalogExist: (function () {
      var self = this;

      var firm = self.get("model").application.objectAt(0).get("firm").toLowerCase();
      var cC = self.get("model").application.objectAt(0).get("kennungland").toLowerCase();

      // let liste = Ember.Set.create();

      if (cC === "uk") {
        cC = "en";
      } else if (cC === "ch") {
        cC = self.get("model").application.objectAt(0).get("spracheinput").toLowerCase();
      }

      var value = false;

      if (typeof etaConfig[firm][cC] !== "undefined" && typeof etaConfig[firm][cC]["files"] !== "undefined" && typeof etaConfig[firm][cC]["files"]["Catalogs"] !== "undefined") {
        if (Object.values(etaConfig[firm][cC]["files"]["Catalogs"]).length > 0) value = true;else {
          value = false;
          self.controllerFor("downloadcenter").set("katalogFehlt", true);
        }
      } else {
        value = false;
      }

      return value;
    }).property("cC"),

    urlProduktkatalog: (function () {
      var self = this;

      var firm = self.get("model").application.objectAt(0).get("firm").toLowerCase();
      var cC = self.get("model").application.objectAt(0).get("kennungland").toLowerCase();

      if (cC === "uk") {
        cC = "en";
      } else if (cC === "ch") {
        cC = self.get("model").application.objectAt(0).get("spracheinput").toLowerCase();
      }

      var katalogurl = "";

      if (typeof etaConfig[firm][cC] !== "undefined" && typeof etaConfig[firm][cC]["files"]["Catalogs"] !== "undefined") {
        var item = etaConfig[firm][cC]["files"]["Catalogs"]["0"];
        katalogurl = "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname;
      }

      var downloadcenter = self.controllerFor("downloadcenter");
      downloadcenter.set("produktkatalog", katalogurl);
      downloadcenter.set("katalogFehlt", false);

      return katalogurl;
    }).property("cC"),

    showImprint: (function () {
      var self = this;

      if (self.get("cC") === "DE" || self.get("cC") === "AT" || self.get("cC") === "CH") {
        return true;
      } else {
        return false;
      }
    }).property("cC")
  });

});